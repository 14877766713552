/* You can add global styles to this file, and also import other style files */

html,
body {
   /* height: 100%;*/
    margin: 0;
    padding: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    /*overflow: -moz-scrollbars-vertical;
    overflow-x: hidden;
    overflow-y: hidden;*/
    height: 100vh;
    
}

/* New Styles */

header
{
    height: 22rem;
    line-height: 22rem;
    vertical-align: middle;
    display: flex;
    align-items:center;
    justify-content:center;
    background: #EE3E80;
}

.login-logo
{
    width: 152px;
    height: 152px;
    border-radius:50%;
    background-image:url("assets/ArthLogo.svg");
    display:block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: #FFFFFF;
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.1), -6px 0px 4px rgba(0, 0, 0, 0.1), inset 4px 0px 4px rgba(0, 0, 0, 0.15), inset 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.otp-logo
{
    width: 152px;
    height: 152px;
    border-radius:50%;
    background-image:url("assets/confirmOtp.svg");
    display:block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: #FFFFFF;
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.1), -6px 0px 4px rgba(0, 0, 0, 0.1), inset 4px 0px 4px rgba(0, 0, 0, 0.15), inset 0px 4px 4px rgba(0, 0, 0, 0.15);
}


.profile-pic
{
    width: 152px;
    height: 152px;
    border-radius:50%;
   
}
.form-layout-login
{
    position: relative;
    width: 60%;
    height: 200px;
    left: calc(50% - 60%/2);
    top: -3rem;
    background: #FFFFFF;
    box-shadow: 3px 6px 16px rgba(113, 84, 84, 0.2);
    border-radius: 16px;
}
.form-layout-otp
{
    position: relative;
    width: 60%;
    height: 160px;
    left: calc(50% - 60%/2);
    top: -3rem;
    background: #FFFFFF;
    box-shadow: 3px 6px 16px rgba(113, 84, 84, 0.2);
    border-radius: 16px;
}


.form-heading
{
    position: relative;
    left: calc(50% - 100%/2);
    top: -5rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
}
.form-label
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    /* identical to box height, or 109% */
    text-align: center;
    letter-spacing: 0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    /* heading/black */
    color: #3E3E3E;
}
.form-btnn
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 76px;
  width: 10rem !important;
  height: 46px;
  left: calc(50% - 10rem/2);
  bottom: 140px;
  background: #EE3E80!important;
  border-radius: 30px!important;
  color: #FFFFFF!important;
}
.form-inputs
{
    border-top: none!important;
    border-left: none!important;
    border-right: none !important;
    border-radius: 0px!important;
}
.form-inputs:focus
{
    box-shadow:none!important
}

.credit-head
{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;

color: #F16599;
}
.credit-limit
{

text-align: center;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;
}

.credit-limit h2
{

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
color: #3E3E3E;
}
.credit-limit label
{

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    
    
    color: #EE3E80;
    
}
.custom-m
{
    margin-top: 2.5rem;
}
.custom-m h4{
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    
    /* tre/light grey */
    
    color: #B0B0B0;
}
.credit-btn
{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 14px 76px;

width: 20rem !important;
height: 46px;
left: calc(50% - 20rem/2);
bottom: 60px;

/* HA button shaw */

box-shadow: 0px 8px 16px rgba(241, 101, 153, 0.2);
background: #EE3E80!important;
  border-radius: 30px!important;
  color: #FFFFFF!important;
}

.left-arrow
{
position: relative;
width: 9px;
height: 19px;
top: 1.5rem;
transform: rotate(360deg);
}

.page-header
{
    
position: relative;

left: calc(50% - 79px/2);

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;

/* heading/black */

color: #3E3E3E;
}

.limit-title
{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;

letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;
color: #B0B0B0;
}
.limit-value
{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;

text-align: right;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;
color: #3E3E3E;
}

.page-container
{
   /* width: 60%;
    position: relative;
    left: calc(50% - 60%/2);
    overflow: hidden;*/
    width: 100%;
    overflow: hidden;
    padding: 1rem;
}

.page-heading
{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;
color: #3E3E3E;
}
.mat-dialog-container
{
    border-radius: 16px!important;
}
.dialog-label
{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
text-align: center;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;
color: #B0B0B0;
}
.dialog-label span
{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;
color: #3E3E3E;
}

.box-model
{
    background: #FFF1F7;
    border-radius: 8px;
    border: 1px solid #FFF1F7;
    padding: 0.5rem;
    margin: 0;
}

.donut-circle-text
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13.8112px;
    line-height: 21px;
    letter-spacing: 0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #B0B0B0;
}
.donut-circle-count
{
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 23.0187px;
line-height: 27px;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;
color: #F16599;
}

.donut-legent-1
{
    border: 1px solid #1CCFC9;
    background: #1CCFC9;
    border-radius: 50%;
}
.donut-legent-2
{
    border: 1px solid #B22567;
    background: #B22567;
    border-radius: 50%;
}

.btn-limit-page
{
    color:#EE3E80!important; 
    border:1px solid#EE3E80!important;
    width: 12rem;
}

.date-lable
{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;
color: #3E3E3E;
}
.trans-comment-repay
{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;

/* heading/black */

color: #3E3E3E;


}
.succes-trans
{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #098A26;
}

.failed-trans
{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
display: flex;
align-items: center;
text-align: right;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;
color: #AB1313;
}
.box-model-trans
{
    background: #FFF5F9;
    border-radius: 16px;
    border: 1px solid #FFF5F9;
    padding: 0.5rem;
    margin: 0;
    text-align: center;
}

.heading-sorry
{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 36px;
text-align: center;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;
color: #3E3E3E;
}

.text-sorry
{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
text-align: center;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;
color: #3E3E3E;

}

.sorry-btn
{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 14px 76px;

width: 20rem !important;
height: 46px;
left: calc(50% - 20rem/2);
bottom: 60px;

box-shadow: 0px 8px 16px rgba(241, 101, 153, 0.2);
border: 1px solid #EE3E80!important;
  border-radius: 30px!important;
  color: #EE3E80!important;
  
}

.trans-title
{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 1rem;
line-height: 24px;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;
color: #3E3E3E;
}

.trans-time
{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 0.8rem;
line-height: 18px;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;
color: #B0B0B0;
}
.trans-symbol
{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 1rem;
line-height: 28px;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;
color: #3E3E3E;
}

.tran-amount
{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 28px;
letter-spacing: 0.01em;
font-feature-settings: 'pnum' on, 'lnum' on;
color: #3E3E3E;
}
/*  END New Styles */

.header {
    width: 105%;
    height: 100%;
    left: -3%;
    position: absolute;
    background-color: #e43779;
    background-size: 100px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 90% 50%;
    border-bottom-right-radius: 90% 50%;
    text-align: center;
    color: #fff;
    padding-top: 10px;
}

.header-2 {
    width: 105%;
    height: 400px;
    left: -3%;
    position: absolute;
    background-color: #e43779;
    background-size: 100px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 90% 50%;
    border-bottom-right-radius: 90% 50%;
    text-align: center;
    color: #fff;
    padding-top: 10px;
}

.container-logo {
    width: 100%;
    height: 300px;
    background-color: white;
    position: relative;
    overflow: hidden;
}

.container-logo-1 {
    width: 100%;
    height: 200px;
    background-color: #e43779;
    position: relative;
    overflow: hidden;
}


.heading-title,
.trans-comment {
    color: #e43779;
    padding: 10px;
    padding-bottom: 0.2em;
}

/*.trans-comment-repay {
    color: #e43779;
    padding: 10px;
}*/

.heading {
    color: #e43779;
}

.heading-t1 {
    font-weight: 800;
}

.heading-t2 {
    font-weight: 400!important;
    /*margin-top: 2.8rem!important;*/
    margin-top: 1.8rem!important;
}

.heading-label {
    font-weight: 500;
    font-size: 1.7rem;
}

/*.form-inputs {
    border-radius: 10px !important;
    border: 1px solid #e43779 !important;
}*/

.form-btn {
    border-radius: 10px!important;
    border-color: #e43779!important;
    background-color: #e43779!important;
    color: #fff!important;
}

.form-btn-no-bg {
    border-radius: 8px!important;
    border: 1px solid #e43779!important;
    color: #e43779!important;
    font-weight: 500!important;
}

.curve-bg {
    border-top-left-radius: 3.2em 2.5em;
    border-top-right-radius: 3.2em 2.5em;
    background-color: #fff;
    position: relative;
    top: -6rem;
    overflow: hidden;
}

.mat-slider {
    height: 300px;
    width: 100px;
}

/*.date-lable {
    color: #e43779;
    font-weight: bold;
    padding: 6px;
}*/

hr.new1 {
    border-top: 1px solid #e43779;
    width: 100%;
}

.trans-box {
    border: 0px solid #ccc;
    border-radius: 12px;
}

.trans-box-1 {
    border: 1px solid #ccc;
    border-radius: 6px;
}

/*.succes-trans {
    color: rgb(74, 226, 74);
}

.failed-trans {
    color: rgb(240, 58, 34);
}*/

.trans-label {
    color: rgb(25, 31, 25);
}

.trans-input {
    color: #e43779;
    font-weight: 500;
}

.amount_label {
    color: #e43779;
    font-weight: 500;
    font-size: 1.5rem;
    background-color: #e5e0f5;
    /*padding: 0.6rem;*/
    width: 50%;
    border-radius: 6px;
}

.mat-bottom-sheet-container {
    border-top-left-radius: 4em 4em!important;
    border-top-right-radius: 4em 4em!important;
    overflow: hidden!important;
}

.mat-slider.mat-slider-horizontal .mat-slider-wrapper {
    top: 18px;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
    height: 8px;
    border-radius: 10px
}

.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    height: 100%;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    background-color: #e43779;
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    background-color: white;
}

.back-arrow {
    position: relative;
    top: 2.4em;
    left: 1.5em;
    font-size: 1.1rem;
}

.mtt-2 {
    padding: null;
}



.trans-details{height:64vh;overflow-y:scroll;} 
  

  .trans-details::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.trans-details {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.limit-value{
    font-size: 1rem;
    font-weight: 500;
}

.logo-img
{
    height: 40px;
    width: 30px;
    padding-top: 20px;
}

.due-box
{
    background-color: #fce8d5;
    border-radius: 15px;
   /* padding: 10px 20px 20px 20px;*/
}
/* set classes for responsive design */

@media only screen and (max-width: 420px){
    .page-container
    {
        padding: 1rem;
        width: 100%;
        left: 0;
    }
header{
    height: 16rem;
}    
.otp-logo,.login-logo {
    width: 8rem;
    height: 8rem;   
    background-size: 2rem;
}
.form-layout-otp,.form-layout-login {
  
    width: 70%;

    left: calc(50% - 70%/2);
}
.form-heading {
    top: -3.5rem;
}
.logo-img
{
    height: 65px;
    width: 30px;
}
.heading-title
{
    font-size: 12px;
}
.container-logo
{
    height: 250px;
}
.container-logo-1
{
    height: 100px;
}
.header-2
{
    
    height: 100px;
}
.curve-bg {
    
    top: -1.6rem;
  
}
}

input[type="range"] {
    -webkit-appearance: none;
    margin-right: 15px;
    width: 300px;
    height: 7px;
    background: rgba(160, 160, 160, 0.6);
    border-radius: 50%/100px 100px 0 0;
    background-image: linear-gradient(#e43779, #e43779);
    background-size: 0% 0%;
    background-repeat: no-repeat;
    top:20px;
    rotate: (180);
    
  }
  
  /* Input Thumb */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #e43779;
    cursor: pointer;
    box-shadow: 0 0 2px 0 rgb(206, 166, 166);
    
  }
  
  input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #e43779;
    cursor: pointer;
    box-shadow: 0 0 2px 0 rgb(216, 155, 155);
    
   
  }
  
  
  input[type="range"]::-moz-range-thumb:hover {
    background: #e43779;
  }
  
  input[type="range"]::-ms-thumb:hover {
    background: #e43779;
  }
  
  /* Input Track */

/*input[type="range"] { 
    margin: auto;
    -webkit-appearance: none;
    position: relative;
    overflow: hidden;
    height: 0.9rem;
    width: 300px;
    cursor: pointer;
    border-radius:  14px; 
}

::-webkit-slider-runnable-track {
    background: #ddd;
}


::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1rem;
    height:1rem;
    border-radius: 50%;
    background: #4c3196;
    box-shadow: -100vw -10vw 0 100vw #4c3196;
    border: 1px solid #fff;
   
}

::-moz-range-track {
    height: 1.25rem;
    background: #ddd;
}

::-moz-range-thumb {
    -webkit-appearance: none;
    width: 0.99rem;
    height: 0.99rem;
    border-radius: 50%;
    background: #4c3196;
    box-shadow: -100vw -10vw 0 100vw #4c3196;
    border: 1px solid #999;
}

::-ms-fill-lower { 
    background: #4c3196;
}

::-ms-thumb { 
    -webkit-appearance: none;
    width: 0.99rem;
    height: 0.99rem;
    border-radius: 50%;
    background: #4c3196;
    box-shadow: -100vw -10vw 0 100vw #4c3196;
    border: 1px solid #999;
}

::-ms-ticks-after { 
    display: none; 
}

::-ms-ticks-before { 
    display: none; 
}

::-ms-track { 
    background: #ddd;
    color: transparent;
    height: 1.25rem;
    border: none;
}

::-ms-tooltip { 
    display: none;
}*/
/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .limit-value {
        font-size: 0.8rem;
    }
    .limit-title{
        font-size: 0.8rem;
    }
    input[type="range"] { 
       
        width: 250px;
       
    }
    .page-container
{
    padding: 1rem;
    width: 100%;
    left: 0;
}
.btn-limit-page
{
    width: 10rem;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .res-mt-4
    {
        margin-top: 1.5rem;
    }
    .res-p-4
    {
        padding: 1.5rem;
    }

    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    

    .form-layout-otp,.form-layout-login{
       
        width: 30%;
       
        left: calc(50% - 30%/2);
        
        }


}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .page-container {
        width: 40%;
        position: relative;
        left: calc(50% - 40%/2);
       
    }

    .form-layout-otp,.form-layout-login{
       
        width: 30%;
       
        left: calc(50% - 30%/2);
        
        }
}
